/*!
 * scripts.js
 *
 * Call any plugins and other regular JavaScript and jQuery code here.
 */

;(function($, window, document, undefined) {

    $(function() {

        // Attaches the togglemenu plugin to the menu button that's visible for
        // mobile users.
        $('.js-toggle-nav').togglemenu({
            nav:    '.nav--main',
            subnav: '.nav--sub',
            item:   '.nav--item',
            link:   '.nav__link',
            back:   '.nav__link--back',
            active:   '.is-active',
            inactive: '.is-inactive',
            open:     '.is-open',
            reset_on_close: true,
            reset_query:    'screen and (min-width: 40em)'
        });

    });

    // AJAX PAGINATION CALL for courses archive, colleges archive and programs archives
    $('#search-container').on('click', '.paginate_links a', function(e){
       
        e.preventDefault();
        var link = jQuery(this).attr('href');
        jQuery('#search-container').html('Loading...');
        jQuery('#search-container').load(link+' .academic-results');
   
    });
    
    // AJAX PAGINATION CALL for HELP/FAQ
    $('#help-container ').on('click','#FAQ  .paginate_links a', function(e){
        e.preventDefault();
        var link = jQuery(this).attr('href');
        jQuery('#help-container #FAQ').html('Loading...');
        jQuery('#help-container ').load(link+'#FAQ .academic-results');
    });

    // AJAX PAGINATION CALL for HELP/LINKS
    $('#Links .paginate_links a').on('click', function(e){
        alert('');
        e.preventDefault();
        var link = jQuery(this).attr('href');
        jQuery('#help-container #Links').html('Loading...');
        jQuery('#help-container #Links').load(link+' .academic-results');
    });

  
    

})(jQuery, window, document);




function modalToggle($a){
  
    $('#modalBack').toggleClass('show');
    if ($('.modal--search').hasClass('show')){
        $('.modal--search').toggleClass('show');
    } else {
     $('#'+$a).toggleClass('show');
    }


}



        
$(document).ready(function() {
    pageRemover();
    $(window).scroll(function() {
        if ($(document).scrollTop() > 50) {
            $('nav').addClass('shrink');
        } else {
            $('nav').removeClass('shrink');
        }

        var scrollPos = $(document).scrollTop();

     $( '.bootstrap-select' ).click(function() {
 // console.log(scrollPos);
 // window.location.hash = '#' + scrollPos;

});       

    });


    //searchScroll();

 

});
        
function searchScroll(){
      $('html, body').animate({
        scrollTop: $('#search-results').offset().top
    }, 0);

}

var carTimer = setInterval(function(){},0);
var $old;
function carousel($div){
    var divItems = $('.selectbox').length;

   
    $('#timer_bar'+$div).fadeIn(0);
    $('#timer_bar'+$div).css({'width':'100%'});  
    $('#select'+$div).addClass('on');
    $('#feat'+ $div).addClass('show');  

    $old = $div;
    $div++;
    if ($div>divItems){
        $div = 1;
    } 
    
    clearInterval(carTimer);
    carTimer = setInterval(function(){

        $('#feat'+ $old).removeClass('show');  
        $('#select'+$old).removeClass('on');

        $('.timer_bar').css({'width':'0%'});
        $('.timer_bar').fadeOut(0);
           
        carousel($div); 

    },8000);

}


function carouselChange($div){
    $('.selectbox').removeClass('on');
    $('.feature-area--inside__holder ').removeClass('show');

    $('.timer_bar').css({'width':'0%'});
    $('.timer_bar').fadeOut(0);
    $old = $div;
     carousel($div);
}

function carouselTimer($div){
       
        
         

     
}


function changeTab($div){
    $('.btn-default--active').removeClass('btn-default--active');
    $('#btn-'+$div).addClass('btn-default--active');
    $('.help-tabs').fadeOut(0);
    $('#'+ $div).fadeIn(30);


}


function pageRemover(){

    if ($( '.page-numbers.current').parent('a')){
     $( '.page-numbers.current').parent('a').replaceWith(function() {
        return $('.page-numbers.current', this);
    });
    }



}

function toggleAcademicBar($div){
    $('.btn--toggle.btn--info').removeClass('on');
    $('div.toggle').removeClass('on');

    $('.btn--toggle.' + $div).addClass('on');
    $('#'+ $div +'_search').addClass('on');
   

}


function dropDownWatcher($x,$y){



url = window.location.href;
url = url.replace('#search-results', '');


  //prefer to use l.search if you have a location/link object
   
    var urlparts= url.split('?');   
    if (urlparts.length>=3) {

        var prefix= encodeURIComponent($y)+'=';
        var pars= urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i= pars.length; i-- > 0;) {    
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                pars.splice(i, 1);
            }
        }

        url= urlparts[0]+'?'+pars.join('&');
        url += '&' +$y+'='+$x+'#search-results';
        return url ;
    } else {

       

       // url += '?&' +$y+'='+$x+'#search-results';
       // return url;
    }



//

}

jQuery(function($) {

    $('#program_list').on('click', '.paginate_links a', function(e){

        e.preventDefault();
        var link = $(this).attr('href');

        $('#program_list .academic-results').fadeOut(500, function(){
            $('#program_list').load(link + ' #program_list .academic-results', function() {
                $('#program_list .academic-results').fadeIn(500);
            });
        });
    });

        $('#course_list').on('click', '.paginate_links a', function(e){
        e.preventDefault();
        var link = $(this).attr('href');
       // alert(link);
        $('#course_list .academic-results').fadeOut(500, function(){
            $('#course_list ').load(link + ' #course_list .academic-results', function() {
                $('#course_list .academic-results').fadeIn(500);
            });
        });
    });
});




/*!
 * Start Bootstrap - Agnecy Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

// jQuery for page scrolling feature - requires jQuery Easing plugin
$(function() {
    
    
    $('a.page-scroll').bind('click', function(event) {
       var $place = 60;
        
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - $place
        }, 1500);
        event.preventDefault();
    });
});


function showinput(){

    $('.form--search').addClass('on');
     setTimeout(function(){
        closeinput();
       },2000);
   // $('.form--search input').blur(function() {
     //if(!$.trim(this.value).length) { // zero-length string AFTER a trim
   // console.log('hi');
           
   //  }
//});




}

function closeinput(){
    setTimeout(function(){
            if (!$('.form--search input').is(':focus')){
               if(!$('.form--search input').val()) { 
                $('.form--search').removeClass('on');
            }

            }
        if($('.form--search').hasClass('on')){
                closeinput();
          
            }

    },2000);
}


function toggle_searchbarmenu(){
    $('.select-style--mobile-list').toggleClass('on');

}



//$('selector').on('swipeleft',function(event){...})

var $total;
var $current;
var $next;

function up_slider_ind($div){
  $total = $('.upcoming--item').length;
    if ($div < $total){
        $current = $div;
        $next = $div+1;
        $next = $div+1;

    } else {
        $current = $total;
        $next = '1';
    }
    $('.upcoming--item.show' ).removeClass('show').addClass('leave');

    $('#slide' + $next).fadeIn(0).addClass('show');
    setTimeout(function(){
    $('#slide' + $div).removeClass('leave').fadeOut(0);

    },500);


}

function up_slide($dir){
   // $total = $('.upcoming--item').length;
//next //prev

if ($dir == 'next' && $('.upcoming--item.show').next('.upcoming--item').length > 0){
    $('.upcoming--item.show').next('.upcoming--item').addClass('nextin');
    $('.dots.current').removeClass('current').next('.dots').addClass('current');


} else if ($dir == 'next' && $('.upcoming--item.show').next('.upcoming--item').length == 0){
    return;
}

if ($dir == 'prev' && $('.upcoming--item.show').prev('.upcoming--item').length > 0){
        $('.upcoming--item.show').prev('.upcoming--item').addClass('previn');
    $('.dots.current').removeClass('current').prev('.dots').addClass('current');
    
} else if ($dir == 'prev' && $('.upcoming--item.show').prev('.upcoming--item').length == 0){
    return;
}
  
  $('.upcoming--item.show').removeClass('show').addClass($dir);
    $('.upcoming--item.'+$dir+'in').fadeIn(0).addClass('show').removeClass($dir+'in');

      setTimeout(function(){
    $('.upcoming--item.'+$dir).removeClass($dir).fadeOut(0);

    },500);



}


function toggleReports(){

    $('#reports').toggleClass('on');

}



function btnSelect($i,$v,$z){
$quiz = '#quiz' + $z;
$div = $quiz + ' #question' +$i;
   
    $($quiz + ' #question'+$i + ' .btn--quiz').removeClass('on');

    if ($v == "yes"){
        $($div + ' .option1').addClass('on');

    } else if ($v == "no"){
        $($div + ' .option2').addClass('on');
    }
   


}

function showAnswer($i){

    $('#answers'+$i).fadeIn(300);
      $('html, body').animate({
        scrollTop: $('#answers' + $i).offset().top - 125
    }, 600);


}

